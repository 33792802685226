import { Link } from 'react-router-dom';

import './home.page.css'


const HomePage = () => {
    return (
        <section>
            <div className='home-first-block'>
                <div className='home-first-block-content'>
                    <div className='home-first-block-content-item'>
                        <h2>Participe da Economia Circular e Lucre com Seus Resíduos!</h2>
                        <p>
                            Nossa plataforma inovadora facilita a conexão com empresas de reciclagem, transformando seus resíduos em recursos valiosos e promovendo a sustentabilidade..
                        </p>

                        <div className='home-first-block-content-item-links'>
                            <Link to='/'>Começar Agora</Link>
                            <Link to='/' className='home-first-block-simple-button'>Saber mais</Link>
                        </div>

                        <br />

                        <h6><strong>Eco Collect</strong> - Liderando a gestão sustentável de resíduos com inovação e eficiência</h6>

                    </div>
                    <div className='home-first-block-content-item'>
                        <img src='images/home-background2.png' alt='home background' />
                    </div>
                </div>
            </div>

            <div className='home-second-block'>
                <div className='home-second-block-content'>
                    <div className='home-second-block-content-item'>
                        <img src='images/wallmart-partner.png' alt='wallmart partner' />
                    </div>
                    <div className='home-second-block-content-item'>
                        <img src='images/petco-partner.png' alt='petco partner' />
                    </div>
                    <div className='home-second-block-content-item'>
                        <img src='images/lin-partner.png' alt='linneaus partner' />
                    </div>
                    <div className='home-second-block-content-item'>
                        <img src='images/petcare-partner.png' alt='petcare partner' />
                    </div>
                </div>
            </div>

            <div className='home-third-block'>
                <div className='home-third-block-content'>
                    <div className='home-third-block-header'>
                        <h2> <span className='White-Service' >NOSSOS SERVIÇOS </span> </h2>
                        <h2>Serviços especializados de coleta e compostagem de resíduos verdes</h2>
                        <p>Promovendo a sustentabilidade e a eficiência. com simplicidade e praticidade de serem integrados à sua rotina, contribuindo para um meio ambiente mais limpo e saudável</p>
                    </div>
                    <div className='home-third-block-itens'>
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-dog"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>Serviços de Coleta de Resíduos Verdes</h1>
                                    <p>A EcoCollect oferece serviços flexíveis de coleta de resíduos verdes, incluindo coletas únicas e quinzenais, para atender às suas necessidades específicas. Nosso objetivo é promover a sustentabilidade e a eficiência na gestão de resíduos.</p>
                                </div>
                            </div>
                        </div>  
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-cat"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>Compostagem e Melhoria do Solo</h1>
                                    <p>Transformamos seus resíduos verdes em melhoradores de solo de alta qualidade para jardins e agricultura. Este processo não só reduz a quantidade de resíduos enviados para aterros, mas também contribui para a redução das emissões de gases de efeito estufa.</p>
                                </div>
                            </div>
                        </div>
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-notes-medical"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>OAgende uma Coleta</h1>
                                    <p>Facilitamos o agendamento de coletas de resíduos verdes de forma simples e rápida. Entre em contato conosco para agendar sua coleta e contribuir para um meio ambiente mais sustentável.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='home-third-block-itens'>
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-stethoscope"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>Grooming & Brushing</h1>
                                    <p>Sit amet nulla facilisi morbi tempus iaculis urna. In cursus turpis massa tincidunt dui ut ornare.</p>
                                </div>
                            </div>
                        </div>
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-user-nurse"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>Pet Walk</h1>
                                    <p>Sit amet nulla facilisi morbi tempus iaculis urna. In cursus turpis massa tincidunt dui ut ornare.</p>
                                </div>
                            </div>
                        </div>
                        <div className='home-third-block-item'>
                            <div className='home-third-block-card'>
                                <div className='home-third-block-card-icon'>
                                    <i className="fa-solid fa-kit-medical"></i>
                                </div>
                                <div className='home-third-block-card-text'>
                                    <h1>Home Visit</h1>
                                    <p>Sit amet nulla facilisi morbi tempus iaculis urna. In cursus turpis massa tincidunt dui ut ornare.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='home-fourth-block'>
                <div className='home-fourth-block-content'>
                    <h3>Tem alguma dúvida ou podemos te ajudar em algo?</h3>
                    <h3>
                        <strong>Entre em contato!</strong>
                    </h3>

                    <Link to='/contato'>Contato</Link>
                </div>
            </div>
        </section>
    )
}

export default HomePage;